<material-navbar [menuPosition]="settings.get('vebto.navbar.defaultPosition')" [container]="true"></material-navbar>

<div class="be-container page-container">
    <h1>{{group?.name}}</h1>

    <p class="group-description" *ngIf="group?.description">{{group.description}}</p>

    <div class="links-container">
        <ul class="group-links skeleton-links unstyled-list" *ngIf="!links && (loading$ | async)" @fadeOut>
            <li class="group-link skeleton-link" *ngFor="let i of [1, 2, 3, 4]">
                <skeleton class="long-url" variant="text"></skeleton>
                <skeleton class="short-url" variant="text"></skeleton>
                <skeleton class="link-description" variant="text"></skeleton>
            </li>
        </ul>

        <ul class="group-links real-links unstyled-list" @fadeIn>
            <li class="group-link" *ngFor="let link of links?.data">
                <a [href]="link.short_url" target="_blank">
                    <div class="long-url">
                        <img class="favicon-img" [src]="link.long_url | favicon" alt="Link favicon">
                        <span>{{removeProtocol(link.long_url)}}</span>
                    </div>
                    <div class="short-url">{{link.short_url}}</div>
                    <p class="link-description" *ngIf="link.description">{{link.description}}</p>
                </a>
            </li>
        </ul>
    </div>

    <loading-indicator [isVisible]="links && (loading$ | async)" class="inline"></loading-indicator>
</div>

<app-footer class="be-container"></app-footer>
